import * as React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../Components/Layout';
import SEO from '../Components/SEO';
import Article from '../Components/Article/Article';
import chevron from '/src/images/svg/chevron_left_black.svg';

const ArticlePage = (props) => {
    const { data, pageContext } = props;
    const { article } = pageContext;

    return (
        <Layout>
            <SEO
                title={article.title}
                description={article.title}
                image={`https://pl.spoton.com/assets/${article.image}`}
            />
            <div
                className={`lg:pl-[160px] lg:pr-64 pr-[16px] md:pl-8 pl-[16px] md:pt-[80px] lg:mx-auto relative`}
            >
                <Link
                    to="/"
                    className="no-underline xl:self-end self-start xl:justify-self-end justify-self-start"
                >
                    <p className="flex items-center justify-center text-black font-sans no-underline font-bold text-[16px] text-center block w-42 max-w-[105px]">
                        <img
                            className="mr-2.5 translate-y-[2px] w-[24px] h-[24px]"
                            alt="chevron icon"
                            src={chevron}
                        />
                        <span className="mt-2">Back</span>
                    </p>
                </Link>
            </div>

            <Article article={article} />
        </Layout>
    );
};

export default ArticlePage;

export const pageQuery = graphql`
    query ArticlePage {
        markdownRemark(frontmatter: { templateKey: { eq: "articles-page" } }) {
            frontmatter {
                articles {
                    title
                    short
                    slug
                    images {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
            }
        }
    }
`;
